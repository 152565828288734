import { Container, Section } from '@components/common'
import React from 'react'

const PoliciesPage = () => {
  return (
    <section className="py-12">
      <Container>
        <div className="sm:w-3/4 mx-auto">
          <h1 className="mb-4">Privacy Policies & Terms of Use</h1>
          <h3>Information collection and use</h3>
          <p>
            If you are merely visiting our website, you are not required to
            provide any personal information and MRS Company does not collect any
            personal information about you. However, If you request more
            information or download our white papers then we will ask you for
            basic contact information such as your name, company affiliation, job
            title, physical mailing address, e-mail address and telephone number.
            MRS Company Limited will use your personal information to contact you
            to provide information about our company and services as well as to
            respond to any specific requests.
          </p>
          <h3>Disclosure of information</h3>
          <p>
            MRS Company Limited will not sell, distribute or disclose your
            personal information, or otherwise share it with a third party. We
            will not collect or distribute information to third parties for any
            purpose. We will not collect or compile personally identifying
            information to third parties for unsolicited marketing activities or
            for consumer marketing initiatives. The only other circumstance in
            which we would disclose your personally identifiable information would
            be if we are required to do so by law through a subpoena, search
            warrant, court order or other legal process.
          </p>
          <h3>Links to third party sites</h3>
          <p>
            Our website may link to third party. MRS Company Limited assumes no
            responsibility for the information practices of sites to which our
            site provides links. You should review each site’s privacy and
            security policies to determine such practices and whether you wish to
            disclose personally identifiable information on them.
          </p>
          <h3>Support persons</h3>
          <p>
            A person with a disability who is accompanied by a support person will
            be allowed to have that person accompany them on our premises.
          </p>
          <h3>Retention of information</h3>
          <p>
            The personally identifiable information you provide will be kept as
            long as the information is required to satisfy the purpose for which
            you provided it or until you request that we delete it. MRS Company
            Limited does not assume responsibility for verifying the ongoing
            accuracy of the information you provide. If you inform us that your
            information has changed, we will make appropriate corrections to
            reflect updated information that you provide.
          </p>
          <h3>Changes to this privacy statement</h3>
          <p>
            MRS Company Limited reserves the right to amend this Privacy Statement
            at any time and for any reason. Nothing contained in this Statement is
            intended or should be construed to create a contract or agreement
            between MRS Company Limited and any user visiting this site or
            providing personally identifiable information in any form.
          </p>
        </div>
      </Container>
    </section>
  )
}
export default PoliciesPage
